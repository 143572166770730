export async function requestAndGetUserMedia(
    permissions = {
        video: true,
    },
) {
    const result = {
        success: false,
        stream: null,
        error: false,
    };

    try {
        const stream = await navigator.mediaDevices.getUserMedia(permissions);
        result.success = true;
        result.stream = stream;
    } catch (err) {
        result.error = err;
    } finally {
        return result;
    }
}
export function checkImageExists(str = "") {
    const pattern = /<img\s+[^>]*src=["']([^"']+)["']/i;

    const match = str.match(pattern);
    return !!match;
}
export function grabImageUrls(str = "") {
    const pattern = /<img[^>]+src="([^"]+)"[^>]*>/g;

    let match;
    const results = [];

    while ((match = pattern.exec(str)) !== null) {
        results.push(match[1]);
    }
    return results;
}

export function getIndexFromImageSrc(tag = "") {
    const arr = tag.split("/");
    return arr[arr.length - 2];
}
